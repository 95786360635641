

@media (max-width: 900px) {
  .main-container{
    -moz-box-align: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    -moz-box-flex: 1;
    flex-grow: 1;
    -moz-box-pack: center;
    justify-content: center;
    padding: 1.5rem 0rem;
    /*max-width: 82.5rem;*/
    box-sizing: border-box;
  }
  .left-container{
    width: auto;
    max-width: 450px;
    padding: 0px 1.5rem;
    margin: auto;
    box-sizing: border-box;
  }

  .right-container{
    width: auto;
    max-width: 450px;
    margin: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 0.3125rem;
    color: rgb(26, 46, 59);
    display: flex;
    flex-direction: column;
    height: auto;
    -moz-box-pack: center;
    justify-content: center;
    padding: 1.5rem 1.5rem;
    min-height: 280px;
    width: 100vw;
    text-align: center;
    box-sizing: border-box;
    /*display: none;*/
  }
}
@media  (min-width: 901px){
  .main-container{
    -moz-box-align: center;
    align-items: flex-start;
    display: flex;
    -moz-box-flex: 1;
    flex-grow: 1;
    -moz-box-pack: center;
    justify-content: center;
    padding: 1.5rem 1.25rem;
    max-width: 82.5rem;
    box-sizing: border-box;
  }
  .left-container{
    width: 20rem;
    margin-right: 4rem;
    box-sizing: border-box;
  }

  .right-container{
    background-color: rgb(255, 255, 255);
    border-radius: 0.3125rem;
    color: rgb(26, 46, 59);
    display: flex;
    flex-direction: column;
    height: auto;
    -moz-box-pack: center;
    justify-content: center;
    padding: 3rem 4rem 5rem;
    min-height: 280px;
    max-width: 31.25rem;
    min-width: 17.5rem;
    text-align: center;
    box-sizing: border-box;
  }
}
