@media (max-width: 900px){
  .header{
    display:none;
  }
}
@media (min-width: 901px){
  .header {
    box-sizing: border-box;
    -moz-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    -moz-box-pack: justify;
    justify-content: space-between;
    left: 0px;
    padding: 0.9375rem 1.25rem;
    position: relative;
    top: 0px;
    width: 100%;
  }
}