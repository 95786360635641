.purchase-success-container{
  -moz-box-align: center;
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  -moz-box-flex: 1;
  flex-grow: 1;
  -moz-box-pack: center;
  justify-content: center;
  padding: 1.5rem 0rem;
  box-sizing: border-box;
  overflow:scroll;
}

.purchase-success-inner-container{
  width: auto;
  max-width: 450px;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 0.3125rem;
  color: rgb(26, 46, 59);
  display: flex;
  flex-direction: column;
  height: auto;
  -moz-box-pack: center;
  justify-content: center;
  padding: 1.5rem 1.5rem;
  min-height: 280px;
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  /*display: none;*/
}

.appstorelogo {
  cursor: pointer;
}